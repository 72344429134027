import { Competence_Programing_Language, Grade } from '@generated/graphql';
import { generateOptions } from 'helpers/index';

const PROGRAMING_LANGUAGE_TITLE: { [key in Competence_Programing_Language]: string } = {
  [Competence_Programing_Language.Java]: 'Java',
  [Competence_Programing_Language.Php]: 'PHP',
  [Competence_Programing_Language.Python]: 'Python',
  [Competence_Programing_Language.Swift]: 'Swift',
  [Competence_Programing_Language.JavaScript]: 'JavaScript',
  [Competence_Programing_Language.VentureInvestment]: 'Venture Investment',
  [Competence_Programing_Language.Kotlin]: 'Kotlin',
  [Competence_Programing_Language.DataScience]: 'Data Science',
  [Competence_Programing_Language.CSharp]: 'C#',
  [Competence_Programing_Language.AqaJava]: 'AQA Java',
  [Competence_Programing_Language.Qa]: 'QA',
  [Competence_Programing_Language.OneC]: '1C',
  [Competence_Programing_Language.SystemAnalyst]: 'Системный аналитик',
  [Competence_Programing_Language.OneCErp]: '1C ERP',
  [Competence_Programing_Language.MachineLearning]: 'Machine Learning',
  [Competence_Programing_Language.SalesManager]: 'Sales Manager',
  [Competence_Programing_Language.ItSalesManager]: 'IT Sales Manager',
  [Competence_Programing_Language.BusinessAnalyst]: 'Бизнес аналитик',
  [Competence_Programing_Language.SupervisorMedComplex]: 'Руководитель медицинского комплекса',
  [Competence_Programing_Language.ProjectManager]: 'Руководитель проектов',
  [Competence_Programing_Language.DesignEngineer]: 'Инженер конструктор',
  [Competence_Programing_Language.OperationsEngineer]: 'Инженер по эксплуатации',
  [Competence_Programing_Language.CallCenterOperator]: 'Оператор колл-центра',
  [Competence_Programing_Language.StoreFloorWorker]: 'Работник торгового зала',
  [Competence_Programing_Language.Cashier]: 'Кассир',
  [Competence_Programing_Language.HrDirector]: 'HR директор',
  [Competence_Programing_Language.LogisticsDirector]: 'Директор по логистике',
  [Competence_Programing_Language.MarketingHead]: 'Руководитель отдела маркетинга',
  [Competence_Programing_Language.ProductHead]: 'Руководитель отдела продукта',
  [Competence_Programing_Language.LogisticsHead]: 'Руководитель отдела логистики',
  [Competence_Programing_Language.DevelopmentDirector]: 'Директор по развитию',
  [Competence_Programing_Language.SecurityDirector]: 'Директор по безопасности',
  [Competence_Programing_Language.SecurityHead]: 'Руководитель отдела безопасности',
  [Competence_Programing_Language.SocialWorker]: 'Социальный работник',
  [Competence_Programing_Language.HomeCareNurse]: 'Медицинская сестра патронажного центра',
  [Competence_Programing_Language.HomeCarePsychologist]: 'Психолог патронажного центра',
  [Competence_Programing_Language.HomeCareCaregiver]: 'Сиделка патронажного центра',
  [Competence_Programing_Language.ClientManager]: 'Менеджер по работе с клиентами',
  [Competence_Programing_Language.ThermalAndElectricalEngineer]: 'Инженер по тепло и электроэнергетике',
  [Competence_Programing_Language.EquipmentServiceTechnician]:
    'Технические специалисты по обслуживанию и ремонту оборудования',
  [Competence_Programing_Language.ProcurementManager]: 'Менеджер по закупкам',
  [Competence_Programing_Language.HeadOfProcurement]: 'Руководитель отдела закупок',
  [Competence_Programing_Language.Ecologist]: 'Эколог',
  [Competence_Programing_Language.EnvironmentalProtectionSpecialist]: 'Специалист по охране окружающей среды',
  [Competence_Programing_Language.OccupationalSafetySpecialist]: 'Специалист по безопасности труда',
  [Competence_Programing_Language.HeadOfOccupationalSafety]: 'Руководитель отдела по безопасности труда',
  [Competence_Programing_Language.HeadOfEnvironmentalProtection]: 'Руководитель отдела по охране окружающей среды',
};

const PROGRAMING_LANGUAGE_OPTIONS = generateOptions(PROGRAMING_LANGUAGE_TITLE);

const GRADE_OPTIONS = [
  {
    id: Grade.Junior,
    displayName: 'Junior',
  },
  {
    id: Grade.Middle,
    displayName: 'Middle',
  },
  {
    id: Grade.Senior,
    displayName: 'Senior',
  },
];

export { PROGRAMING_LANGUAGE_TITLE, PROGRAMING_LANGUAGE_OPTIONS, GRADE_OPTIONS };
